import { createContext } from 'react';

import { isNotProduction } from '~/buses/debugMenu';

export type Flag = 'DISTRICT_SUPERINTENDENT_USER_EXPERIENCE';

export interface IDebugFlag {
  enabled: boolean;
  loading: boolean;
}

export interface IDebugContextData {
  superintendentFlag: IDebugFlag;
  setFlag?: (flag: Flag, value: boolean) => void;
}

export const DebugFlagContext = createContext<IDebugContextData>({
  superintendentFlag: { enabled: isNotProduction, loading: true },
} as IDebugContextData);
