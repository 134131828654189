import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { startCase } from 'lodash';
import * as React from 'react';

import NAMES from '~/constants/names';
import TAG_IDS from '~/constants/tagIds';
import localCache from '~/helpers/localCache';
import useLandingPageRedirect from '~/hooks/useLandingPageRedirect';
import { EncouraSecondarySchoolWithRole } from '~/types/schoolSelection';

import { StyledLink, StyledLinkGrid, StyledLocaleTypography, StyledRoleTypography } from './styles';

interface ILinkProps {
  closeDialog?: () => void;
  linkProps?: React.HTMLAttributes<HTMLLIElement>;
  maxItemSize?: number;
  menuWidth?: number;
  school: Partial<EncouraSecondarySchoolWithRole>;
  shouldWrapLabel?: boolean | 0;
  showCityAndState?: boolean;
}

const SchoolLink: React.FC<ILinkProps> = props => {
  const { linkProps, school, showCityAndState = false, closeDialog, shouldWrapLabel = false } = props;

  const handleOnClick = (): void => {
    localCache.set(NAMES.SCHOOL_ID, school.hsId);
    if (closeDialog) closeDialog();
  };

  const { url } = useLandingPageRedirect(school.hsId as string);

  return (
    <li {...linkProps} style={{ padding: 0 }}>
      <Grid item xs={12}>
        <StyledLink href={url} id={TAG_IDS.SCHOOL_SEARCH.SEARCH_SCHOOL_LINK(school.hsId as string)} onClick={handleOnClick} underline="none" variant="body1">
          <StyledLinkGrid item xs={12}>
            <Grid container justifyContent="space-between" rowGap={1}>
              <Grid item xs={shouldWrapLabel ? 12 : 8}>
                <Typography variant="body1">{school.name}</Typography>
              </Grid>
              {!showCityAndState && 'role' in school && (
                <Grid container item justifyContent={shouldWrapLabel ? 'start' : 'end'} xs={shouldWrapLabel ? 12 : 4}>
                  <StyledRoleTypography sx={{ color: theme => theme.palette.text.overLight.medium }} variant="body1">
                    {startCase(school?.role as string)}
                  </StyledRoleTypography>
                </Grid>
              )}
              {showCityAndState && (
                <Grid container item justifyContent={shouldWrapLabel ? 'start' : 'end'} xs={shouldWrapLabel ? 12 : 4}>
                  <StyledLocaleTypography variant="body1">
                    {school?.address?.city && school?.address?.state && `${school?.address?.city}, ${school?.address?.state}`}
                  </StyledLocaleTypography>
                </Grid>
              )}
            </Grid>
          </StyledLinkGrid>
        </StyledLink>
      </Grid>
    </li>
  );
};

export default SchoolLink;
