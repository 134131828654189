import { useCallback, useEffect, useState, ReactNode, useContext, useMemo } from 'react';

import { isNotProduction, isProduction } from '~/buses/debugMenu';
import { DISTRICT_SUPERINTENDENT_USER_EXPERIENCE } from '~/constants/featureFlags';
import { IDebugFlag, DebugFlagContext, Flag, IDebugContextData } from '~/hooks/useDebugMenu/context';
import useFeatureFlags from '~/hooks/useFeatureFlags';

interface IChildrenProps {
  children: ReactNode;
}

export function DebugFlagsProvider({ children }: IChildrenProps): ReactNode {
  const [superintendentFlag, setSuperintendentFlag] = useState<IDebugFlag>({ enabled: isNotProduction, loading: true });

  const { data: superintendentFlagEnabled, loading: loadingSuperintendentFlag } = useFeatureFlags()(DISTRICT_SUPERINTENDENT_USER_EXPERIENCE);

  const loadSuperintendent = useCallback(() => {
    if (isProduction) {
      setSuperintendentFlag({ enabled: superintendentFlagEnabled, loading: false });
      return;
    }
    const localFlag = global?.window?.localStorage.getItem(DISTRICT_SUPERINTENDENT_USER_EXPERIENCE);

    const enabled = localFlag ? localFlag === 'true' : superintendentFlagEnabled;
    setSuperintendentFlag({ enabled, loading: false });
  }, [superintendentFlagEnabled]);

  useEffect(() => {
    if (loadingSuperintendentFlag) return;

    loadSuperintendent();
  }, [loadSuperintendent, loadingSuperintendentFlag]);

  const setFlag = useCallback((flag: Flag, value: boolean) => {
    if (isProduction) return;
    global?.window?.localStorage.setItem(flag, value.toString());

    if (flag === DISTRICT_SUPERINTENDENT_USER_EXPERIENCE) {
      setSuperintendentFlag({ enabled: value, loading: false });
    }
  }, []);

  const contextValue = useMemo(() => ({ setFlag, superintendentFlag }), [setFlag, superintendentFlag]);

  return <DebugFlagContext.Provider value={contextValue}>{children}</DebugFlagContext.Provider>;
}

const useDebugMenu = (): IDebugContextData => {
  const context = useContext(DebugFlagContext);

  if (!context) {
    throw new Error('useDebugMenu must be used within a DebugFlagsProvider');
  }

  return context;
};

export default useDebugMenu;
