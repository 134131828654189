import ENV from '~/constants/env';

const getEnvironment = (): 'development' | 'staging' | 'production' => {
  if (ENV?.API_BASE_URL?.includes('dev')) {
    return 'development';
  }

  if (ENV?.API_BASE_URL?.includes('stg')) {
    return 'staging';
  }

  return 'production';
};

export default getEnvironment;
